import { useCallback } from "react";
import { useAuth } from "./useAuth";
import {
  useDeleteBookmarkMutation,
  useInsertBookmarkMutation,
} from "graphql/generated";
import { fireEvent } from "lib/ga";

export const useBookmark = (
  content: Pick<Mix_Content, "id" | "bookmarked" | "originalurl">,
) => {
  const { isLoggedIn, openSignInModal } = useAuth();

  const [insertBookmark] = useInsertBookmarkMutation({
    optimisticResponse: (variables) => {
      return {
        insert_mix_content_bookmark_one: {
          id: content.id,
          mix_content: {
            id: variables.contentId,
            bookmarked: true,
          },
        },
      };
    },
    onCompleted: () => {
      fireEvent({
        event: "content_bookmark",
        params: {
          content_id: content.id,
          content_url: content.originalurl,
          value: true,
        },
      });
    },
  });

  const [deleteBookmark] = useDeleteBookmarkMutation({
    optimisticResponse: (variables) => {
      return {
        delete_mix_content_bookmark: {
          returning: [
            {
              id: content.id,
              mix_content: {
                id: variables.contentId,
                bookmarked: false,
              },
            },
          ],
        },
      };
    },
    onCompleted: () => {
      fireEvent({
        event: "content_bookmark",
        params: {
          content_id: content.id,
          content_url: content.originalurl,
          value: false,
        },
      });
    },
  });

  const toggle = useCallback(() => {
    if (isLoggedIn) {
      if (!content.bookmarked) {
        insertBookmark({
          variables: {
            contentId: content.id,
          },
        });
      } else {
        deleteBookmark({
          variables: {
            contentId: content.id,
          },
        });
      }
    } else {
      openSignInModal();
    }
  }, [
    content.id,
    content.bookmarked,
    isLoggedIn,
    insertBookmark,
    deleteBookmark,
    openSignInModal,
  ]);

  return {
    toggle,
  };
};
