import { ComponentProps, useMemo } from "react";
import { useRouter } from "next/router";
import { ExtensionLink } from "../../core/ExtensionLink";
import { ContentGrid } from "./ContentGrid";
import { css } from "@emotion/react";
import { Button } from "components/core/buttons/Button";
import { ContentCard } from "components/domain/contents/cards/ContentCard";
import { ContentCardSekeleton } from "components/domain/contents/cards/ContentCardSkeleton";
import { SectionScaffold } from "components/domain/contents/sections/SectionScaffold";
import { useMixContentQuery } from "graphql/generated";
import { fireEvent } from "lib/ga";
import _ from "lodash";
import { slidein } from "styles/animation/slidein";
import { LIMIT, buildQueryVariables } from "utils/buildQueryVariables";
import { ORDERED_MIX_CONTENT_TAG_ENUM } from "utils/enum";

export const MixedContentGrid = ({
  contents,
  loading,
  showSkeleton = false,
  empty,
}: ComponentProps<typeof ContentGrid>) => {
  const router = useRouter();

  const tag = useMemo(
    () =>
      ORDERED_MIX_CONTENT_TAG_ENUM.find((tag) => tag === router.query.tag) ??
      null,
    [router],
  );

  const { data } = useMixContentQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      ...buildQueryVariables("original", tag, LIMIT.original),
      limit: LIMIT.original,
      offset: 0,
    },
  });

  const _originalContents = useMemo(
    () => (data ? data.mix_content : []),
    [data],
  );

  const [originalContents, recentContents] = useMemo(
    () =>
      _.partition(
        _originalContents.slice(0, 2).concat(contents),
        (content) => content.isoriginal,
      ),
    [contents, _originalContents],
  );

  const showEmpty = useMemo(
    () => !loading && recentContents.length === 0,
    [loading, recentContents.length],
  );

  const getCardProps = (content: Mix_ContentFragment) => {
    return {
      type: content.isoriginal
        ? content.released
          ? ("original" as const)
          : ("upcoming" as const)
        : ("curation" as const),
      content,
      onClick: () => {
        const params = {
          content_section: content.isoriginal
            ? ("original-main" as const)
            : ("recent" as const),
          content_url: content.originalurl,
          content_isoriginal: content.isoriginal,
          content_released: content.released,
        };

        fireEvent({
          event: "content_click",
          params: {
            content_id: content.id,
            content_title: content.title,
            ...params,
          },
        });

        // window.fbq("track", "ViewContent", {
        //   ...params,
        //   content_ids: [content.id],
        //   content_name: content.title,
        //   content_type: "product",
        // });
      },
    };
  };

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        animation: 600ms ${slidein};

        ${showEmpty
          ? css`
              display: flex;
              align-items: center;
              justify-content: center;
            `
          : css`
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
              grid-gap: 64px 24px;
            `};
      `}
    >
      {contents.length > 0 ? (
        <>
          {tag === null && (
            <div
              css={css`
                position: relative;
                display: grid;
                grid-gap: 64px 24px;
                grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
                grid-auto-flow: column;
                grid-column: 3 / span 2;
                grid-row: 1;

                & > * {
                  min-width: 270px;
                }

                @media (max-width: 1550px) {
                  grid-column: 3;

                  & > *:nth-of-type(2) {
                    display: none;
                  }
                }
              `}
            >
              <SectionScaffold.Header
                css={css`
                  position: absolute;
                  top: -48px; // SectionScaffold.Header margin-bottom의 2배 (24px * 2)
                  display: flex;
                  width: 100%;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <SectionScaffold.Title>오리지널 콘텐츠</SectionScaffold.Title>
                <SectionScaffold.Controls>
                  <ExtensionLink href="/original">
                    <Button
                      size="small"
                      color="grey"
                      variant="text"
                      endIcon="ChevronRight"
                    >
                      더보기
                    </Button>
                  </ExtensionLink>
                </SectionScaffold.Controls>
              </SectionScaffold.Header>
              {originalContents.map((content) => (
                <ContentCard key={content.id} {...getCardProps(content)} />
              ))}
            </div>
          )}
          {recentContents.map((content) => (
            <ContentCard key={content.id} {...getCardProps(content)} />
          ))}
        </>
      ) : loading ? (
        showSkeleton && (
          <>
            <ContentCardSekeleton />
            <ContentCardSekeleton />
            <ContentCardSekeleton />
            <ContentCardSekeleton />
            <ContentCardSekeleton />
            <ContentCardSekeleton />
          </>
        )
      ) : (
        empty
      )}
    </div>
  );
};
