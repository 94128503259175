import { ComponentProps } from "react";
import { Interpolation, Theme, css } from "@emotion/react";
import { Text } from "components/core/Text";

export const ContentTags = ({
  tags,
  color,
  ...props
}: {
  tags: Mix_ContentFragment["mix_content_tags"];
  color?: ComponentProps<typeof Text>["color"];
  css?: Interpolation<Theme>;
}) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        overflow: hidden;
      `}
      {...props}
    >
      {tags.map((tag) => (
        <Text
          key={tag.mix_content_tag_enum.value}
          variant="body4"
          color={color || "primary.dark"}
          css={css`
            &:not(:last-child)::after {
              content: "·";
              margin: 0 4px;
            }
          `}
        >
          {tag.mix_content_tag_enum.comment}
        </Text>
      ))}
    </div>
  );
};
