import React, { useMemo } from "react";
import { Seperator } from "../../core/Seperator";
import { ContentCard } from "./cards/ContentCard";
import { css } from "@emotion/react";
import { ContentCardSekeleton } from "components/domain/contents/cards/ContentCardSkeleton";
import { fireEvent } from "lib/ga";
import { slidein } from "styles/animation/slidein";

export const ContentGrid = ({
  contents,
  contentSection,
  loading,
  showSkeleton = false,
  empty,
}: {
  contents: Array<Mix_ContentFragment>;
  contentSection: ContentSection;
  loading: boolean;
  showSkeleton?: boolean;
  empty?: React.ReactNode;
}) => {
  const showEmpty = useMemo(
    () => !loading && contents.length === 0,
    [loading, contents.length],
  );

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        animation: 600ms ${slidein};

        ${showEmpty
          ? css`
              display: flex;
              align-items: center;
              justify-content: center;
            `
          : css`
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
              grid-gap: 64px 24px;

              @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                row-gap: 24px;
              }
            `};
      `}
    >
      {contents.length > 0
        ? contents.map((content) => (
            <React.Fragment key={content.id}>
              <ContentCard
                type={
                  content.isoriginal
                    ? content.released
                      ? "original"
                      : "upcoming"
                    : "curation"
                }
                content={content}
                onClick={() => {
                  const params = {
                    content_section: contentSection,
                    content_url: content.originalurl,
                    content_isoriginal: content.isoriginal,
                    content_released: content.released,
                  };

                  fireEvent({
                    event: "content_click",
                    params: {
                      content_id: content.id,
                      content_title: content.title,
                      ...params,
                    },
                  });

                  // window.fbq("track", "ViewContent", {
                  //   ...params,
                  //   content_ids: [content.id],
                  //   content_name: content.title,
                  //   content_type: "product",
                  // });
                }}
              />
              <Seperator
                css={css`
                  display: none;

                  @media (max-width: 768px) {
                    display: flex;
                    width: auto;
                    margin: 0 calc(-1 * var(--page-padding));
                  }
                `}
              />
            </React.Fragment>
          ))
        : loading
        ? showSkeleton && (
            <>
              <ContentCardSekeleton />
              <ContentCardSekeleton />
              <ContentCardSekeleton />
              <ContentCardSekeleton />
              <ContentCardSekeleton />
              <ContentCardSekeleton />
            </>
          )
        : empty}
    </div>
  );
};
