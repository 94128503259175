import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { HTMLAttributes } from "react";

const Skeleton = styled.div`
  background-color: currentColor;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentCardSekeleton = ({}: HTMLAttributes<HTMLDivElement>) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        color: ${theme.palette.grey[200]};
      `}
    >
      <Card>
        <div
          css={css`
            position: relative;
            width: 100%;
            padding-top: calc(100% / 1.6);
            background-color: currentColor;
            border-radius: 12px;
          `}
        />
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            margin-top: 4.4%;
          `}
        >
          <Skeleton
            css={css`
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              border-radius: 100%;
              margin-right: 8px;
              overflow: hidden;
              box-shadow: rgba(0, 0, 0, 0.5) 0 0 1px;
              flex-shrink: 0;
            `}
          />
          <Column>
            <Skeleton
              css={css`
                height: 24px;
                border-radius: 4px;
                margin-bottom: 6px;
              `}
            />
            <Skeleton
              css={css`
                width: 70%;
                height: 24px;
                border-radius: 4px;
              `}
            />
          </Column>
        </div>
      </Card>
    </div>
  );
};
