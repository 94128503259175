import { ClassAttributes, HTMLAttributes } from "react";
import { IconBox } from "./IconBox";
import { Text } from "./Text";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";

type ChipProps = ClassAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLDivElement> & {
    css?: Interpolation<Theme>;
    variant?: "default" | "variant2" | "variant3";
  };

export const Chip = ({
  variant = "default",
  children,
  ...props
}: ChipProps) => {
  const theme = useTheme();

  const { iconColor, iconBgColor, textColor, textBgColor } = {
    default: {
      iconColor: theme.palette.common.white.main,
      iconBgColor: theme.palette.primary.main,
      textColor: theme.palette.common.white.main,
      textBgColor: theme.palette.subcolor.backGroundBalck,
    },
    variant2: {
      iconColor: theme.palette.common.white.main,
      iconBgColor: theme.palette.subcolor.orange,
      textColor: theme.palette.common.white.main,
      textBgColor: theme.palette.subcolor.backGroundBalck,
    },
    variant3: {
      iconColor: theme.palette.primary.main,
      iconBgColor: "transparent",
      textColor: theme.palette.primary.main,
      textBgColor: "transparent",
    },
  }[variant];

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        height: 24px;
        width: max-content;
        border-radius: 10px;
        overflow: hidden;
      `}
      {...props}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding-left: 2px;
          color: ${iconColor};
          background-color: ${iconBgColor};

          ${variant === "variant3" &&
          css`
            padding-left: 0;
          `}
        `}
      >
        <IconBox name="Original_small" size="medium" />
      </div>
      <Text
        css={css`
          padding: 4px ${theme.shape.radius.medium}px;
          color: ${textColor};
          background-color: ${textBgColor};

          ${variant === "variant3" &&
          css`
            padding-left: 0;
            padding-right: 0;
          `};
        `}
        variant="caption2"
      >
        {children}
      </Text>
    </div>
  );
};
