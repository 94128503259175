import { ComponentProps } from "react";
import { ContentCard } from "../ContentCard";
import { ContentCardScaffold } from "./ContentCardScaffold";
import { css } from "@emotion/react";

export const HorizontalCard = ({
  content,
  ...props
}: Omit<ComponentProps<typeof ContentCard>, "type">) => {
  return (
    <ContentCardScaffold {...props}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <ContentCardScaffold.SourceAuthorHeader
          css={css`
            width: 100%;
          `}
          sourceImgSize={32}
          sourceImgUrl={content.mix_source_info.imgurl}
          authorname={content.authorname}
          updatetime={content.updatetime}
          offlinetime={content.offlinetime}
          category={content.category}
        />
        <ContentCardScaffold.Bookmark variant="iconDark" content={content} />
      </div>
      <div
        css={css`
          display: flex;
          gap: 12px;
          margin-top: 14px;
          margin-bottom: 10px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex: 1;
          `}
        >
          <ContentCardScaffold.Title>{content.title}</ContentCardScaffold.Title>
          <ContentCardScaffold.Abstract>
            {content.abstractcontents}
          </ContentCardScaffold.Abstract>
        </div>
        <ContentCardScaffold.ThumbnailImg
          css={css`
            border-radius: 8px;
          `}
          src={content.imgurl ?? ""}
          width={70}
          height={70}
          loader={({ src }) => src + "?w=210"}
        />
      </div>
      <ContentCardScaffold.Tags tags={content.mix_content_tags} />
    </ContentCardScaffold>
  );
};
