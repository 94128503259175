import Image from "next/image";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";

export const ContentSourceImg = ({
  width = 32,
  height = 32,
  imgurl,
  ...props
}: {
  width?: number;
  height?: number;
  imgurl: string;
  css?: Interpolation<Theme>;
}) => {
  const theme = useTheme();

  return (
    <Image
      css={css`
        border-radius: ${theme.shape.radius.medium}px;
      `}
      alt="source"
      src={imgurl}
      width={width}
      height={height}
      loader={({ src }) => src + `?w=${width * 3}`}
      {...props}
    />
  );
};
