import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export const formatDate = (from: Date, to = new Date()) => {
  const startOfFrom = dayjs(from).startOf("D");
  const startOfTo = dayjs(to).startOf("D");

  const diff = dayjs.duration(startOfTo.diff(startOfFrom));

  const years = diff.years();
  const months = diff.months();
  const weeks = diff.weeks();
  const days = diff.days();

  if (years > 0) {
    return `${years}년전`;
  } else if (months > 0) {
    return `${months}달전`;
  } else if (weeks > 0) {
    return `${weeks}주전`;
  } else if (days > 1) {
    return `${days}일전`;
  } else if (days === 1) {
    return `어제`;
  } else {
    return `오늘`;
  }
};
