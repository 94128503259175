import { ComponentProps } from "react";
import { BoldText } from "./BoldText";
import { Interpolation, Theme } from "@emotion/react";

export const SectionTitle = ({
  children,
  ...props
}: ComponentProps<typeof BoldText> & {
  css?: Interpolation<Theme>;
}) => {
  return (
    <BoldText tag="h2" {...props}>
      {children}
    </BoldText>
  );
};
