import { useEffect } from "react";
import _ from "lodash";

export const useHitBottom = ({ fetch }: { fetch: () => void }) => {
  useEffect(() => {
    const callback = _.throttle(() => {
      if (
        window.innerHeight + window.scrollY + 100 >=
        document.body.offsetHeight
      ) {
        fetch();
      }
    }, 300);

    window.addEventListener("scroll", callback);

    return () => window.removeEventListener("scroll", callback);
  }, [fetch]);
};
