import { ComponentProps } from "react";
import { Interpolation, Theme } from "@emotion/react";
import { Text } from "components/core/Text";

export const BoldText = ({
  children,
  ...props
}: Omit<ComponentProps<typeof Text>, "variant"> & {
  css?: Interpolation<Theme>;
}) => {
  return (
    <Text variant="h4" color="common.black.main" {...props}>
      {children}
    </Text>
  );
};
