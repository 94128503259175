import { keyframes } from "@emotion/react";

export const slidein = keyframes`
  from {
      opacity: 0;
      transform: translateY(30px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
`;
