import { Mix_Content_Status_Enum, Order_By } from "graphql/generated";

export const LIMIT = {
  default: 16,
  original: 4,
};

export const buildQueryVariables = (
  section: IndexPageContentSection | null,
  tag: Mix_Content_Tag_Enum_Enum | null,
  limit: number,
): MixContentQueryVariables => ({
  where: {
    status: {
      _eq: Mix_Content_Status_Enum.Published,
    },
    ...(tag
      ? {
          mix_content_tags: {
            mix_content_tag_enum: {
              value: {
                _eq: tag,
              },
            },
          },
        }
      : {}),
    ...(section === "original"
      ? {
          isoriginal: {
            _eq: true,
          },
        }
      : section === "recent"
      ? {
          isoriginal: {
            _eq: false,
          },
        }
      : {}),
  },
  orderBy: [{ updatedate: Order_By.Desc }, { id: Order_By.Asc }],
  limit,
  offset: 0,
});
