import { ComponentProps } from "react";
import { ContentCard } from "../ContentCard";
import { ContentCardScaffold } from "./ContentCardScaffold";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const ThumnailBox = styled.div`
  position: relative;
  width: 100%;
  height: 180px;
  border-radius: 12px;
  margin-bottom: 12px;
`;

const ThumnailOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;

export const VerticalHalfCard = ({
  content,
  interaction,
  ...props
}: Omit<ComponentProps<typeof ContentCard>, "type">) => {
  return (
    <ContentCardScaffold
      css={css`
        .bookmark {
          display: none;
        }

        .thumbnail-overlay {
          background: 50% / cover no-repeat;
        }

        :hover,
        &[data-interaction="hover"],
        &[data-bookmarked="true"] {
          .bookmark {
            display: flex;
          }

          .thumbnail-overlay {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.2) 100%
              ),
              50% / cover no-repeat;
          }
        }
      `}
      data-interaction={interaction}
      data-bookmarked={!!content.bookmarked}
      {...props}
    >
      <ThumnailBox>
        <ContentCardScaffold.ThumbnailImg
          css={css`
            border-radius: inherit;
          `}
          src={content.imgurl ?? ""}
          loader={({ src }) => src + "?w=768"}
          fill={true}
        />
        <ThumnailOverlay className="thumbnail-overlay" />
        <ContentCardScaffold.Bookmark
          css={css`
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1;
          `}
          className="bookmark"
          variant="filledDark"
          content={content}
        />
      </ThumnailBox>
      <ContentCardScaffold.SourceAuthorHeader
        sourceImgSize={24}
        sourceImgUrl={content.mix_source_info.imgurl}
        authorname={content.authorname}
        updatetime={content.updatetime}
        offlinetime={content.offlinetime}
        category={content.category}
      />
      <div
        css={css`
          display: flex;
          gap: 12px;
          margin-top: 12px;
          margin-bottom: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}
        >
          <ContentCardScaffold.Title>{content.title}</ContentCardScaffold.Title>
          <ContentCardScaffold.Abstract>
            {content.abstractcontents}
          </ContentCardScaffold.Abstract>
        </div>
      </div>
      <ContentCardScaffold.Tags tags={content.mix_content_tags} />
    </ContentCardScaffold>
  );
};
