import { ClassAttributes, HTMLAttributes } from "react";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";

export const Seperator = ({
  ...props
}: ClassAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLDivElement> & { css?: Interpolation<Theme> }) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        width: 100%;
        height: 12.25px;
        border-top: 1.75px solid #eee;
        background-color: ${theme.palette.grey[100]};
      `}
      {...props}
    />
  );
};
