import { ComponentProps } from "react";
import { ToggleIcon } from "./ToggleIcon";
import { useTheme } from "@emotion/react";

export function Bookmark({
  variant = "filledDark",
  ...props
}: {
  variant: "filledDark" | "filledLight" | "iconDark" | "iconLight";
} & Omit<ComponentProps<typeof ToggleIcon>, "iconSpec">) {
  const theme = useTheme();

  const { unchecked, checked } = (
    {
      filledDark: {
        unchecked: {
          enabled: {
            color: theme.palette.grey[300],
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: 8,
          },
          hover: {
            color: theme.palette.common.white.main,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        checked: {
          enabled: {
            color: theme.palette.subcolor.orange,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: 8,
          },
          hover: {
            color: theme.palette.subcolor.orange,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
      filledLight: {
        unchecked: {
          enabled: {
            color: theme.palette.grey[400],
            backgroundColor: theme.palette.grey[200],
            borderRadius: 8,
          },
          hover: {
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.grey[200],
          },
        },
        checked: {
          enabled: {
            color: theme.palette.subcolor.orange,
            backgroundColor: theme.palette.grey[200],
            borderRadius: 8,
          },
          hover: {
            color: theme.palette.subcolor.orange,
            backgroundColor: theme.palette.grey[200],
          },
        },
      },
      iconDark: {
        unchecked: {
          enabled: {
            color: theme.palette.grey[400],
            backgroundColor: "transparent",
            borderRadius: 8,
          },
          hover: {
            color: theme.palette.grey[400],
            backgroundColor: "transparent",
          },
        },
        checked: {
          enabled: {
            color: theme.palette.subcolor.orange,
            backgroundColor: "transparent",
            borderRadius: 8,
          },
          hover: {
            color: theme.palette.subcolor.orange,
            backgroundColor: "transparent",
          },
        },
      },
      iconLight: {
        unchecked: {
          enabled: {
            color: theme.palette.grey[100],
            backgroundColor: "transparent",
            borderRadius: 8,
          },
          hover: {
            color: theme.palette.grey[300],
            backgroundColor: "transparent",
          },
        },
        checked: {
          enabled: {
            color: theme.palette.subcolor.orange,
            backgroundColor: "transparent",
            borderRadius: 8,
          },
          hover: {
            color: theme.palette.subcolor.orange,
            backgroundColor: "transparent",
          },
        },
      },
    } as const
  )[variant];

  return (
    <ToggleIcon
      iconSpec={{
        unchecked: {
          name: "BookmarkOutlined",
          ...unchecked,
        },
        checked: {
          name: "BookmarkFilled",
          ...checked,
        },
      }}
      {...props}
    />
  );
}
