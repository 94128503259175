import { ClassAttributes, HTMLAttributes } from "react";
import { ContentAbstract } from "../components/ContentAbstract";
import { ContentAuthorName } from "../components/ContentAuthorName";
import { ContentBookmark } from "../components/ContentBookmark";
import { ContentCategoryChip } from "../components/ContentCategoryChip";
import { ContentSourceAuthorHeader } from "../components/ContentSourceAuthorHeader";
import { ContentSourceImg } from "../components/ContentSourceImg";
import { ContentTags } from "../components/ContentTags";
import { ContentThumbnailImg } from "../components/ContentThumbnailImg";
import { ContentTitle } from "../components/ContentTitle";
import { ContentUpdateTime } from "../components/ContentUpdateTime";
import { Interpolation, Theme, css } from "@emotion/react";

export const ContentCardScaffold = (
  props: ClassAttributes<HTMLElement> &
    HTMLAttributes<HTMLElement> & {
      css?: Interpolation<Theme>;
    },
) => {
  return (
    <article
      css={css`
        display: flex;
        flex-direction: column;
        cursor: pointer;
      `}
      {...props}
    />
  );
};

ContentCardScaffold.SourceAuthorHeader = ContentSourceAuthorHeader;
ContentCardScaffold.SourceImg = ContentSourceImg;
ContentCardScaffold.AuthorName = ContentAuthorName;
ContentCardScaffold.UpdateTime = ContentUpdateTime;
ContentCardScaffold.Title = ContentTitle;
ContentCardScaffold.Abstract = ContentAbstract;
ContentCardScaffold.Bookmark = ContentBookmark;
ContentCardScaffold.CategoryChip = ContentCategoryChip;
ContentCardScaffold.Tags = ContentTags;
ContentCardScaffold.ThumbnailImg = ContentThumbnailImg;
