import { ClassAttributes, HTMLAttributes } from "react";
import { ContentAuthorName } from "./ContentAuthorName";
import { ContentCategoryChip } from "./ContentCategoryChip";
import { ContentSourceImg } from "./ContentSourceImg";
import { ContentUpdateTime } from "./ContentUpdateTime";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import { Mix_Content_Category_Enum } from "graphql/generated";

type ContentSourceAuthorHeaderProps = {
  sourceImgSize: number;
  sourceImgUrl: Mix_ContentFragment["mix_source_info"]["imgurl"];
  authorname: Mix_ContentFragment["authorname"];
  updatetime: Mix_ContentFragment["updatetime"];
  offlinetime: Mix_ContentFragment["offlinetime"];
  category: Mix_ContentFragment["category"];
} & ClassAttributes<HTMLElement> &
  HTMLAttributes<HTMLElement> & {
    css?: Interpolation<Theme>;
  };

export const ContentSourceAuthorHeader = ({
  sourceImgSize,
  sourceImgUrl,
  authorname,
  updatetime,
  offlinetime,
  category,
  ...props
}: ContentSourceAuthorHeaderProps) => {
  const theme = useTheme();

  return (
    <header
      css={css`
        display: flex;
        align-items: center;
        gap: ${theme.shape.radius.medium}px;
      `}
      {...props}
    >
      {sourceImgUrl && (
        <ContentSourceImg
          width={sourceImgSize}
          height={sourceImgSize}
          imgurl={sourceImgUrl}
        />
      )}
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <ContentAuthorName>{authorname}</ContentAuthorName>
          {category && (
            <ContentCategoryChip variant="variant3" category={category} />
          )}
        </div>
        <ContentUpdateTime
          updatetime={updatetime}
          offlinetime={offlinetime}
          isOffline={category === Mix_Content_Category_Enum.Offline}
        />
      </div>
    </header>
  );
};
