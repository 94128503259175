import { ComponentProps } from "react";
import Image from "next/image";
import { Interpolation, Theme, css } from "@emotion/react";

export const ContentThumbnailImg = ({
  ...props
}: {
  css?: Interpolation<Theme>;
} & Omit<ComponentProps<typeof Image>, "alt">) => {
  return (
    <Image
      css={css`
        object-fit: cover;
        object-position: center;
      `}
      alt="thumnail"
      {...props}
    />
  );
};
