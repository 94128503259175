import { ComponentProps } from "react";
import { ContentCard } from "./ContentCard";
import { HorizontalCard } from "./scaffolds/HorizontalCard";
import { VerticalFullCard } from "./scaffolds/VerticalFullCard";
import { css } from "@emotion/react";

const desktopStyle = css`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

const mobileStyle = css`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const OriginalContentCard = ({
  device,
  ...props
}: Omit<ComponentProps<typeof ContentCard>, "type">) => {
  const map = {
    "web+tablet": <VerticalFullCard {...props} />,
    app: <HorizontalCard {...props} />,
  };

  return device ? (
    map[device]
  ) : (
    <>
      <VerticalFullCard css={desktopStyle} {...props} />
      <HorizontalCard css={mobileStyle} {...props} />
    </>
  );
};
