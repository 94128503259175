import { ComponentProps } from "react";
import { css } from "@emotion/react";
import { Text } from "components/core/Text";

export const ContentAbstract = (
  props: Omit<ComponentProps<typeof Text>, "variant">,
) => {
  return (
    <Text
      css={css`
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
      `}
      variant="body4"
      color="text.secondary"
      {...props}
    />
  );
};
